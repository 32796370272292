<template>
  <div>
    <div class="book_details" v-if="resultSummary.book">
      <img :src="resultSummary.book.coverimage[0]" alt="">
      <div>
          <h5> {{ resultSummary.book.title }} </h5>
          <h6> {{ resultSummary.book.author[0] }} </h6>
      </div>
    </div>
    <h1>You got {{ resultSummary.score }} </h1>
    <div>
      <p>No of questions {{ resultSummary.quiz_count }}</p>
      <p class="correct">correct answers {{ correctAnswers }} </p>
      <p class="wrong">Wrong answers {{ wrongAnswers }}</p>
      <p class="not_done">Not answered {{ notAnswered }}</p>
    </div>
    <div class="question_numbers">
      <span
          v-for="(page, questionIndex) in resultSummary.quizzes"
          :key="questionIndex"
          >
        {{ questionIndex + 1 }}
      </span>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import '../../../router';

const { mapActions, mapGetters } = createNamespacedHelpers('staging/test');
export default {
  name: 'testResult',
  data() {
    return {
      bookId: this.$route.params.book_id,
      testId: this.$route.params.test_id,
      correctAnswers: null,
      wrongAnswers: null,
      notAnswered: null,
    };
  },
  methods: {
    ...mapActions(['getResultOfTest']),
  },
  computed: {
    ...mapGetters(['resultSummary']),
  },
  mounted() {
    if (!this.resultSummary || (this.resultSummary.length === 0)) {
      this.getResultOfTest(this.testId).then(() => {
        const notAnswerdArray = [];
        const correctAnswersArray = [];
        const wrongAnswersArray = [];
        this.resultSummary.quizzes.forEach((element) => {
          if ((element.user_answers.length === 0) && (element.correct_answer === false)) {
            notAnswerdArray.push(element);
          } else if ((element.user_answers.length > 0) && (element.correct_answer === false)) {
            wrongAnswersArray.push(element);
          } else if ((element.user_answers.length > 0) && (element.correct_answer === true)) {
            correctAnswersArray.push(element);
          }
        });

        this.correctAnswers = correctAnswersArray.length;
        this.notAnswered = notAnswerdArray.length;
        this.wrongAnswers = wrongAnswersArray.length;
      });
    }
  },
};
</script>

<style lang="stylus" scoped>
  .book_details
    img
      height 100px
      width 100px
  .correct
    color green
  .wrong
    color red
    border: 2px solid red
  .not_done
    color yellow
    border: 2px solid yellow
  .question_numbers
    text-align: center;

    .question_number
      border: 2px solid grey;
      margin: .1rem;
      padding: 5px 10px;
      cursor: pointer;

    &_correct
      border: 2px solid green
      color green
    &_wrong
      border: 2px solid red
      color red
    &_wrong
      border: 2px solid yellow
      color yellow
</style>
